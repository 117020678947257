<template>
  <div
    class="post"
    data-transaction-name="Blog - Go To Post"
    @click="goToPost(identifier)"
  >
    <SfImage
      :src="imageUrl(img)"
      :lazy="isLazy"
    />
    <ABlogTimeAndViews
      :date="date"
      :views="views"
    />
    <div class="post__description">
      {{ title }}
    </div>
    <div
      class="post__options"
      :class="{'post__options-hide': isCheckedVote}"
      @click.stop=""
    >
      <span
        class="post__options-like"
        data-transaction-name="Blog - Up Vote"
        @click="upVote(postId, mutableLike)"
      >
        <SfIcon
          class="post__icon icon-views"
          width="16"
          height="12"
        >
          <svg
            width="16"
            height="14"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.33335 5.99995H3.33335V14H1.33335C1.15654 14 0.986973 13.9297 0.861949 13.8047C0.736925 13.6797 0.666687 13.5101 0.666687 13.3333V6.66662C0.666687 6.48981 0.736925 6.32024 0.861949 6.19521C0.986973 6.07019 1.15654 5.99995 1.33335 5.99995ZM4.86202 5.13795L9.12869 0.871286C9.1854 0.814415 9.26073 0.77993 9.34083 0.774173C9.42094 0.768417 9.50043 0.791775 9.56469 0.839952L10.1334 1.26662C10.2913 1.38518 10.4106 1.54785 10.4762 1.73412C10.5418 1.92039 10.5508 2.12192 10.502 2.31329L9.73335 5.33329H14C14.3536 5.33329 14.6928 5.47376 14.9428 5.72381C15.1929 5.97386 15.3334 6.313 15.3334 6.66662V8.06929C15.3335 8.24353 15.2996 8.41611 15.2334 8.57729L13.17 13.5873C13.1197 13.7094 13.0342 13.8139 12.9244 13.8873C12.8146 13.9608 12.6855 14 12.5534 14H5.33335C5.15654 14 4.98697 13.9297 4.86195 13.8047C4.73693 13.6797 4.66669 13.5101 4.66669 13.3333V5.60928C4.66672 5.43249 4.73699 5.26295 4.86202 5.13795Z" fill="#757679" />
          </svg>
        </SfIcon>
        <span>{{ mutableLike }}</span>
      </span>
      <span
        class="post__options-dislike"
        data-transaction-name="Blog - Down Vote"
        @click="downVote(postId, mutableDislike)"
      >
        <SfIcon
          class="post__icon icon-views"
          width="16"
          height="12"
        >
          <svg
            width="16"
            height="14"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M14.6666 8.00005H12.6666V4.86374e-05H14.6666C14.8435 4.86374e-05 15.013 0.0702868 15.1381 0.195311C15.2631 0.320335 15.3333 0.489904 15.3333 0.666716V7.33338C15.3333 7.51019 15.2631 7.67976 15.1381 7.80479C15.013 7.92981 14.8435 8.00005 14.6666 8.00005ZM11.138 8.86205L6.87131 13.1287C6.8146 13.1856 6.73927 13.2201 6.65917 13.2258C6.57906 13.2316 6.49957 13.2082 6.43531 13.16L5.86665 12.7334C5.70871 12.6148 5.58942 12.4521 5.52381 12.2659C5.4582 12.0796 5.44921 11.8781 5.49798 11.6867L6.26665 8.66671H1.99998C1.64636 8.66671 1.30722 8.52624 1.05717 8.27619C0.807122 8.02614 0.666646 7.687 0.666646 7.33338V5.93071C0.666466 5.75647 0.700439 5.58389 0.766645 5.42271L2.82998 0.412715C2.88031 0.29057 2.9658 0.186134 3.0756 0.112661C3.18539 0.0391884 3.31454 -6.67572e-06 3.44665 4.86374e-05H10.6666C10.8435 4.86374e-05 11.013 0.0702868 11.1381 0.195311C11.2631 0.320335 11.3333 0.489904 11.3333 0.666716V8.39072C11.3333 8.56751 11.263 8.73705 11.138 8.86205Z" fill="#757679" />
          </svg>
        </SfIcon>
        <span>{{ mutableDislike }}</span>
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { getThumbnailPath } from '@vue-storefront/core/helpers'
import { SfImage, SfIcon } from '@storefront-ui/vue'
import ABlogTimeAndViews from 'theme/components/atoms/a-blog-time-and-views.vue'

export default {
  name: 'MBlogPost',
  components: {
    SfImage,
    SfIcon,
    ABlogTimeAndViews
  },
  props: {
    postId: {
      type: [Number, String],
      default: 0
    },
    img: {
      type: String,
      default: null
    },
    isLazy: {
      type: Boolean,
      default: true
    },
    date: {
      type: [Date, String],
      default: () => {
        return '2022-11-11 00:00:00'
      }
    },
    views: {
      type: [Number, String],
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    like: {
      type: [Number, String],
      default: 0
    },
    dislike: {
      type: [Number, String],
      default: 0
    },
    identifier: {
      type: String,
      require: true
    }
  },
  data () {
    return {
      isCheckedVote: false,
      mutableLike: this.like || 0,
      mutableDislike: this.dislike || 0
    }
  },
  computed: {
    ...mapGetters({
      isLoadingVote: 'blog/getLoadingVote'
    })
  },
  mounted () {
    this.checkVote()
  },
  updated () {
    this.checkVote()
  },
  methods: {
    ...mapActions({
      updateVote: 'blog/updateVote',
      isVote: 'blog/checkVote'
    }),
    async checkVote () {
      this.isCheckedVote = await this.isVote({ postId: this.postId })
    },
    imageUrl (url) {
      return getThumbnailPath(`/${url}`)
    },
    async upVote (postId, vote) {
      const voteStatus = await this.updateVote({
        postId,
        voteType: 'upvote',
        vote
      })

      this.mutableLike = voteStatus
    },
    async downVote (postId, vote) {
      const voteStatus = await this.updateVote({
        postId,
        voteType: 'downvote',
        vote
      })

      this.mutableDislike = voteStatus
    },
    goToPost (url) {
      this.$router.push(this.localizedRoute(`/blog/${url}`))
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.post {
  &__icon {
    width: px2rem(14.5);
  }

  &__description {
    display: -webkit-box;
    font-weight: var(--font-medium);
    font-size: var(--font-size-13);
    line-height: var(--font-size-16);
    color: var(--black);
    padding: 5px 0;
    -webkit-line-clamp: 4;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    max-height: px2rem(61);
    overflow: hidden;

    @include for-desktop {
      font-size: var(--font-size-15);
      line-height: var(--font-size-18);
    }
  }

  ::v-deep .sf-image {
    height: 100%;
    min-width: 100%;
    width: 100%;
    --image-width: 100%;

    img {
      height: px2rem(88);
      min-width: px2rem(170);
    }

    @include for-tablet {
      img {
        height: px2rem(123);
        min-width: px2rem(238);
      }
    }

    @include for-desktop {
      img {
        height: px2rem(150);
        min-width: px2rem(290);
      }
    }
  }

  &__options {
    display: flex;

    &-hide {
      opacity: 0.5;
      pointer-events: none;
    }

    &-like {
      margin-right: var(--spacer-27);

      > span {
        padding-top: 5px;
      }
    }

    &-dislike {
      padding-top: 5px;
    }

    &-like,
    &-dislike {
      font-size: var(--spacer-12);
      color: var(--dark-gray);
      cursor: pointer;
    }

    span {
      display: flex;
      align-items: center;
      width: var(--spacer-35);

      div {
        margin-right: 6px;
      }
    }
  }
}
</style>
